<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="card_box" v-for="(item,i) in list" :key="'card'+i" @click="handleClick(item)">
      <el-card shadow="hover">
        <img class="card_img" :src="item.PicLink">
        <div style="padding: 14px;">
          <span>{{ item.Name }}</span>
        </div>
      </el-card>
    </div> -->
    <div id="js_article" style="position:relative;" class="rich_media">


      <div id="js_base_container" class="rich_media_inner">


        <div id="page-content" class="rich_media_area_primary">
          <div class="rich_media_area_primary_inner">

            <div id="img-content" class="rich_media_wrp">

              <h1 class="rich_media_title " id="activity-name">


                <el-link :href="yourExternalPageURL" class="rich_media_title" target="_blank">“影像”联盟 和衷共济（转载）</el-link>
              </h1>
              <div id="meta_content" class="rich_media_meta_list">

                <span class="rich_media_meta rich_media_meta_nickname" id="profileBt">
                  <a href="javascript:void(0);" class="wx_tap_link js_wx_tap_highlight weui-wa-hotarea" id="js_name">
                    空军军医大学唐都医院 </a>
                  <div id="js_profile_qrcode" aria-hidden="true" class="profile_container" style="display:none;">
                    <div class="profile_inner">
                      <strong class="profile_nickname">空军军医大学唐都医院</strong>
                      <img class="profile_avatar" id="js_profile_qrcode_img" src="" alt="">

                      <p class="profile_meta">
                        <label class="profile_meta_label">微信号</label>
                        <span class="profile_meta_value">tdwxgzpt</span>
                      </p>

                      <p class="profile_meta">
                        <label class="profile_meta_label">功能介绍</label>
                        <span class="profile_meta_value">唐都医院创建于1939年。1958年总参批准为第四军医大学第二附属医院，
                          2017年随学校转隶更名为空军军医大学第二附属医院，是一所集医疗、教学、科研、预防、保健、康复为一体的现代化三级甲等医院。拥有先进的检查设备和人性化的诊疗系统。</span>
                      </p>
                    </div>
                    <span class="profile_arrow_wrp" id="js_profile_arrow_wrp">
                      <i class="profile_arrow arrow_out"></i>
                      <i class="profile_arrow arrow_in"></i>
                    </span>
                  </div>
                </span>
                <span id="meta_content_hide_info">
                  <em id="publish_time" class="rich_media_meta rich_media_meta_text">2024-01-09 18:36</em>
                  <em id="js_ip_wording_wrp" class="rich_media_meta rich_media_meta_text" role="option"
                    aria-labelledby="js_a11y_op_ip_wording js_ip_wording" style="display: inline-block;"><span
                      id="js_a11y_op_ip_wording" aria-hidden="true">发表于</span><span aria-hidden="true"
                      id="js_ip_wording">陕西</span></em>
                </span>
              </div>


































              <div class="rich_media_content js_underline_content
                       defaultNoSetting
            " id="js_content" style="visibility: visible;">
                <section
                  style="color: rgb(0, 0, 0); letter-spacing: 1px; line-height: 2; padding: 0px 10px; box-sizing: border-box; font-style: normal; font-weight: 400; text-align: justify; font-size: 16px; visibility: visible;">
                  <section
                    style="margin: 10px 0% 0px; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;"
                    powered-by="xiumi.us">
                    <section
                      style="display: inline-block; width: 100%; vertical-align: top; border-style: solid; border-width: 1px; border-color: rgb(0, 79, 190); background-color: rgb(255, 255, 255); align-self: flex-start; flex: 0 0 auto; box-sizing: border-box; visibility: visible;">
                      <section
                        style="text-align: center; margin: 0px 0px 10px; line-height: 0; box-sizing: border-box; visibility: visible;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
                          <img class="rich_pages wxw-img" data-ratio="0.562962962962963" data-s="300,640"
                            data-src="@/assets/xw/img1.jpg" data-w="1080"
                            style="vertical-align: middle; max-width: 100%; width: 655px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="1" src="@/assets/xw/img1.jpg" _width="100%" crossorigin="anonymous" alt="图片"
                            data-fail="0">
                        </section>
                      </section>
                      <section style="margin: 10px 0px 0px; box-sizing: border-box; visibility: visible;"
                        powered-by="xiumi.us">
                        <section
                          style="color: rgb(0, 0, 0); padding: 0px 10px; box-sizing: border-box; visibility: visible;">
                          <p
                            style="text-indent: 2.125em; white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
                            日月其迈，时盛岁新。1月6日，空军军医大学唐都医院举办“2024年陕西省放射诊疗质控年会暨陕西磁共振影像及陕西胸部影像专科联盟成立大会”，50余位国内知名专家和联盟内177家成员单位齐聚唐都，共论影像。
                          </p>
                        </section>
                      </section>
                      <section
                        style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box; visibility: visible;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img2.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 655px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="2" src="@/assets/xw/img2.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="margin: 10px 0px 0px;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="color: rgb(0, 0, 0);padding: 0px 10px;box-sizing: border-box;">
                          <p
                            style="text-indent: 2.125em;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            大会以
                            “合作交流、资源共享、共同发展”为宗旨，加强放射诊疗技术规范化和同质化建设，完善联盟各医院人才培养体系，为成员单位学科建设提供可靠的组织基础和高效的交流平台，并推动我省放射诊疗水平高质量发展。
                          </p>
                        </section>
                      </section>
                      <section
                        style="text-align: center;justify-content: center;display: flex;flex-flow: row;margin: 10px 0%;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;vertical-align: middle;width: auto;flex: 100 100 0%;height: auto;align-self: center;margin: 0px 10px 0px 0px;border-width: 0px;box-sizing: border-box;">
                          <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="background-color: rgb(152, 186, 189);height: 1px;box-sizing: border-box;"><svg
                                viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: top;width: auto;flex: 0 0 0%;height: auto;line-height: 0;z-index: 3;box-sizing: border-box;">
                          <section style="text-align: left;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 16px;height: 16px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);border-width: 1px;border-radius: 26px;border-style: solid;border-color: rgb(152, 186, 189);box-sizing: border-box;">
                              <svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: top;width: auto;flex: 0 0 0%;height: auto;margin: 0px 0px 0px -8px;line-height: 0;box-sizing: border-box;">
                          <section style="text-align: left;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 16px;height: 16px;vertical-align: top;overflow: hidden;background-color: rgb(251, 228, 151);border-width: 0px;border-radius: 26px;border-style: none;border-color: rgb(62, 62, 62);box-sizing: border-box;">
                              <svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: middle;width: auto;flex: 100 100 0%;height: auto;margin: 0px 0px 0px 10px;align-self: center;box-sizing: border-box;">
                          <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="background-color: rgb(152, 186, 189);height: 1px;box-sizing: border-box;"><svg
                                viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img3.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="3" src="@/assets/xw/img3.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="padding: 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
                        <p
                          style="text-indent: 2.125em;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                          陕西省卫健委医政医管局刘伟副局长指出，唐都医院放射科作为陕西省放射诊疗质控中心依托单位，此次牵头成立陕西磁共振影像及陕西胸部影像专科联盟，近乎覆盖陕西省所有二级以上医院，将进一步高效推进陕西省医疗机构间放射影像检查资料的同质化及检查结果互认工作。
                        </p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img4.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="4" src="@/assets/xw/img4.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="padding: 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
                        <p
                          style="text-indent: 2.125em;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                          唐都医院王敬博院长充分肯定了近几年唐都医院放射科在教医研人才梯队及7.0T磁共振、光子计数CT等国际顶尖设备优势平台建设和发展，同时强调医疗机构之间影像学检查同质化和结果互认工作意义深远，质控工作在基层推进任重道远，希望与联盟成员一起和衷共济，携手同行，竭诚为广大患者服务。
                        </p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img5.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="5" src="@/assets/xw/img5.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="padding: 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
                        <p
                          style="text-indent: 2.125em;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                          国家放射影像专业质控中心主任兼中国放射医师分会会长、北京协和医院金征宇教授表示，专科联盟成立正当其时，希望陕西同道再接再厉，在质控工作中严格遵循相关法律法规和标准规范文件，以此次大会为契机，促进互相交流，取得更好成绩。
                        </p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img6.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="6" src="@/assets/xw/img6.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="padding: 0px 10px;color: rgb(0, 0, 0);box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <p
                          style="text-indent: 2.125em;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                          陕西省放射诊疗质量控制中心主任、唐都医院放射科崔光彬主任表示，通过优势资源下沉，进一步完善各医疗机构放射影像诊断流程、应急预案、诊疗规范等，秉承“抓质控、求规范、促交流、共发展”的理念，让广大患者享有高效精准的放射影像诊疗服务。
                        </p>
                      </section>
                      <section
                        style="text-align: center;justify-content: center;display: flex;flex-flow: row;margin: 10px 0%;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;vertical-align: middle;width: auto;flex: 100 100 0%;height: auto;align-self: center;margin: 0px 10px 0px 0px;border-width: 0px;box-sizing: border-box;">
                          <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="background-color: rgb(152, 186, 189);height: 1px;box-sizing: border-box;"><svg
                                viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: top;width: auto;flex: 0 0 0%;height: auto;line-height: 0;z-index: 3;box-sizing: border-box;">
                          <section style="text-align: left;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 16px;height: 16px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);border-width: 1px;border-radius: 26px;border-style: solid;border-color: rgb(152, 186, 189);box-sizing: border-box;">
                              <svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: top;width: auto;flex: 0 0 0%;height: auto;margin: 0px 0px 0px -8px;line-height: 0;box-sizing: border-box;">
                          <section style="text-align: left;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 16px;height: 16px;vertical-align: top;overflow: hidden;background-color: rgb(251, 228, 151);border-width: 0px;border-radius: 26px;border-style: none;border-color: rgb(62, 62, 62);box-sizing: border-box;">
                              <svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: middle;width: auto;flex: 100 100 0%;height: auto;margin: 0px 0px 0px 10px;align-self: center;box-sizing: border-box;">
                          <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="background-color: rgb(152, 186, 189);height: 1px;box-sizing: border-box;"><svg
                                viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;justify-content: center;display: flex;flex-flow: row;margin: 10px 0px;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: auto;vertical-align: top;align-self: flex-start;flex: 0 0 auto;border-style: solid;border-width: 1px;border-color: rgb(231, 35, 18);padding: 3px;min-width: 5%;max-width: 100%;height: auto;box-sizing: border-box;">
                          <section
                            style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;background-color: rgb(231, 35, 18);padding: 6px;box-sizing: border-box;">
                              <section style="text-align: center;color: rgb(255, 255, 255);box-sizing: border-box;"
                                powered-by="xiumi.us">
                                <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                                    style="box-sizing: border-box;">陕西磁共振影像专科联盟</strong></p>
                                <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                                    style="box-sizing: border-box;">陕西胸部影像专科联盟</strong></p>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;justify-content: center;display: flex;flex-flow: row;margin: 10px 0px 0px;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: auto;vertical-align: top;align-self: flex-start;flex: 0 0 auto;background-color: rgb(0, 112, 193);min-width: 5%;max-width: 100%;height: auto;border-radius: 5px;overflow: hidden;box-sizing: border-box;">
                          <section style="color: rgb(255, 255, 255);padding: 0px 10px;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                                style="box-sizing: border-box;">揭牌仪式</strong><br style="box-sizing: border-box;"></p>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img7.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="7" src="@/assets/xw/img7.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section
                        style="text-align: center;justify-content: center;display: flex;flex-flow: row;margin: 10px 0px 0px;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: auto;vertical-align: top;align-self: flex-start;flex: 0 0 auto;background-color: rgb(0, 112, 193);min-width: 5%;max-width: 100%;height: auto;border-radius: 5px;overflow: hidden;box-sizing: border-box;">
                          <section style="color: rgb(255, 255, 255);padding: 0px 10px;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                                style="box-sizing: border-box;">颁发副理事长聘书</strong><br style="box-sizing: border-box;"></p>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img8.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="8" src="@/assets/xw/img8.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section
                        style="text-align: center;justify-content: center;display: flex;flex-flow: row;margin: 10px 0%;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;vertical-align: middle;width: auto;flex: 100 100 0%;height: auto;align-self: center;margin: 0px 10px 0px 0px;border-width: 0px;box-sizing: border-box;">
                          <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="background-color: rgb(152, 186, 189);height: 1px;box-sizing: border-box;"><svg
                                viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: top;width: auto;flex: 0 0 0%;height: auto;line-height: 0;z-index: 3;box-sizing: border-box;">
                          <section style="text-align: left;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 16px;height: 16px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);border-width: 1px;border-radius: 26px;border-style: solid;border-color: rgb(152, 186, 189);box-sizing: border-box;">
                              <svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: top;width: auto;flex: 0 0 0%;height: auto;margin: 0px 0px 0px -8px;line-height: 0;box-sizing: border-box;">
                          <section style="text-align: left;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 16px;height: 16px;vertical-align: top;overflow: hidden;background-color: rgb(251, 228, 151);border-width: 0px;border-radius: 26px;border-style: none;border-color: rgb(62, 62, 62);box-sizing: border-box;">
                              <svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: middle;width: auto;flex: 100 100 0%;height: auto;margin: 0px 0px 0px 10px;align-self: center;box-sizing: border-box;">
                          <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="background-color: rgb(152, 186, 189);height: 1px;box-sizing: border-box;"><svg
                                viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;justify-content: center;display: flex;flex-flow: row;margin: 10px 0px;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: auto;vertical-align: top;align-self: flex-start;flex: 0 0 auto;border-style: solid;border-width: 1px;border-color: rgb(231, 35, 18);padding: 3px;min-width: 5%;max-width: 100%;height: auto;box-sizing: border-box;">
                          <section
                            style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;background-color: rgb(231, 35, 18);padding: 6px;box-sizing: border-box;">
                              <section style="text-align: justify;color: rgb(255, 255, 255);box-sizing: border-box;"
                                powered-by="xiumi.us">
                                <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                                    style="box-sizing: border-box;">专题授课</strong></p>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img9.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="9" src="@/assets/xw/img9.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">中华放射学分会主任委员</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">上海长征医院 刘士远 教授</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">《人工智能对医学影像全流程质控的赋能》</strong></p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img10.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="10" src="@/assets/xw/img10.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="text-align: center;color: rgb(0, 0, 0);padding: 0px 10px;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">中华放射学分会常委、磁共振学组组长</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">上海瑞金医院 严福华 教授</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">《肝脏磁共振检查规范》</strong></p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img11.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="11" src="@/assets/xw/img11.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="text-align: center;color: rgb(0, 0, 0);padding: 0px 10px;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">国家放射影像医疗质控中心副主任委员</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">北京协和医院 薛华丹 教授</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">《中国放射影像危急值工作现状与展望》</strong></p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img12.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="12" src="@/assets/xw/img12.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="text-align: center;padding: 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">中华放射学分会常委兼影像质控学组组长</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">北京同仁医院 鲜军舫 教授</strong></p>
                        <p
                          style="text-align: justify;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                          <strong style="box-sizing: border-box;">《建立以病人为中心的影像质量控制与管理体系》</strong>
                        </p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img13.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="13" src="@/assets/xw/img13.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="text-align: center;padding: 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">中国放射医师分会常委兼总干事</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">北京友谊医院 杨正汉 教授</strong></p>
                        <p
                          style="text-align: justify;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                          <strong style="box-sizing: border-box;">《制度化信息化智慧化并举、多院区放射科高同质运营》</strong>
                        </p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img14.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="14" src="@/assets/xw/img14.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="text-align: center;padding: 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">中华放射学分会乳腺学组副组长</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">华中科技大学同济医学院附属协和医院</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">杨帆 教授</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">《乳腺B3病变》</strong></p>
                      </section>
                      <section
                        style="margin: 10px 0%;text-align: left;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(216, 202, 160);line-height: 0;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                          <section
                            style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row;box-sizing: border-box;"
                            powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 100%;vertical-align: top;background-position: 0% 0%;background-repeat: repeat;background-size: 1.56658%;background-attachment: scroll;padding: 0px;align-self: flex-start;flex: 0 0 auto;background-image: url(&quot;https://mmbiz.qpic.cn/mmbiz_png/V4P6mqfetCAI0SyV8X9JX2ee3koZYwBqPbGGxDxia9dXSWib8oiaY0efjTO7zibvlo9REt5PVYoTrEcXERFexgjqyQ/640?wx_fmt=png&quot;);box-sizing: border-box;">
                              <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us">
                                <section
                                  style="display: inline-block;width: 100%;height: 11px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);box-sizing: border-box;">
                                  <svg viewBox="0 0 1 1"
                                    style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img15.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="15" src="@/assets/xw/img15.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="text-align: center;padding: 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">国际心血管CT协会中国区主席</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">中国医学科学院阜外医院</strong></p>
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                            style="box-sizing: border-box;">吕滨&nbsp; 教授</strong></p>
                        <p
                          style="text-align: justify;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                          <strong style="box-sizing: border-box;">《CT血流储备分数操作规范及临床应用中国专家共识》</strong>
                        </p>
                      </section>
                      <section
                        style="text-align: center;justify-content: center;display: flex;flex-flow: row;margin: 10px 0%;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="display: inline-block;vertical-align: middle;width: auto;flex: 100 100 0%;height: auto;align-self: center;margin: 0px 10px 0px 0px;border-width: 0px;box-sizing: border-box;">
                          <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="background-color: rgb(152, 186, 189);height: 1px;box-sizing: border-box;"><svg
                                viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: top;width: auto;flex: 0 0 0%;height: auto;line-height: 0;z-index: 3;box-sizing: border-box;">
                          <section style="text-align: left;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 16px;height: 16px;vertical-align: top;overflow: hidden;background-color: rgba(255, 255, 255, 0);border-width: 1px;border-radius: 26px;border-style: solid;border-color: rgb(152, 186, 189);box-sizing: border-box;">
                              <svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: top;width: auto;flex: 0 0 0%;height: auto;margin: 0px 0px 0px -8px;line-height: 0;box-sizing: border-box;">
                          <section style="text-align: left;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section
                              style="display: inline-block;width: 16px;height: 16px;vertical-align: top;overflow: hidden;background-color: rgb(251, 228, 151);border-width: 0px;border-radius: 26px;border-style: none;border-color: rgb(62, 62, 62);box-sizing: border-box;">
                              <svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                        <section
                          style="display: inline-block;vertical-align: middle;width: auto;flex: 100 100 0%;height: auto;margin: 0px 0px 0px 10px;align-self: center;box-sizing: border-box;">
                          <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="background-color: rgb(152, 186, 189);height: 1px;box-sizing: border-box;"><svg
                                viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
                        powered-by="xiumi.us">
                        <section
                          style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                          <img data-ratio="0.562962962962963" data-s="300,640" data-w="1080"
                            data-src="@/assets/xw/img16.jpg"
                            style="vertical-align: middle; max-width: 100%; width: 328px !important; box-sizing: border-box; height: auto !important; visibility: visible !important;"
                            width="100%"
                            data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;"
                            data-index="16" src="@/assets/xw/img16.jpg" class="" _width="100%" crossorigin="anonymous"
                            alt="图片" data-fail="0">
                        </section>
                      </section>
                      <section style="margin: 0px 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="padding: 0px 10px;box-sizing: border-box;">
                          <p
                            style="text-indent: 2.125em;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            大道如砥，行者无疆。陕西省放射诊疗质控中心将继续秉承新理念，拓展新技术，与全体影像人一起创新求实，拼搏奋进，聚焦新质控，开启新篇章，统筹优势资源，规范影像标准，解决诊断难题，为人民健康保驾护航。
                          </p>
                        </section>
                      </section>
                    </section>
                  </section>
                  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
                    <br style="box-sizing: border-box;">
                  </p>
                  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
                    <br style="box-sizing: border-box;">
                  </p>
                  <section style="box-sizing: border-box;" powered-by="xiumi.us">
                    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">作者：杜丽娟 姚 璐</p>
                    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">编辑：张晓雷 孔 乾</p>
                    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">责编：岳丽颖&nbsp;刘婷婷</p>
                    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">审核：周天翔</p>
                  </section>
                </section>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getZXInfo } from '@/api'
export default {
  name: "XSJX",
  data() {
    return {
      list: [],
      yourExternalPageURL: "https://mp.weixin.qq.com/s/L6fbBmS6SEaIHL_hS95G1w",
    }
  },
  methods: {
    async getZXInfoAsync() {
      const r = await getZXInfo();
      if (r.code == 1) {
        this.list = r.data;
      } else {
        this.$message.error(r.error.Message);
      }
    },
    handleClick(row) {
      const link = row.Link;
      window.open(link)
    },
  },
  created() {
    // this.getZXInfoAsync();
  },
}
</script>

<style scoped>
.card_box {
  width: 350px;
  cursor: pointer;
}

.card_img {
  width: 300px;
}

.rich_media_area_primary_inner {
  max-width: 677px;
  margin: 0 auto;
}

.rich_media_meta_list {
  margin-bottom: 22px;
  line-height: 20px;
  font-size: 0;
  word-wrap: break-word;
  hyphens: auto;
}

.rich_media_meta {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px 0;
  font-size: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: #576b95;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rich_media_meta_list em {
  font-style: normal;
}

.rich_media_meta_text {
  color: rgba(255, 255, 255, .35);
  color: rgba(0, 0, 0, .3);
}

.rich_media_title {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
}
</style>